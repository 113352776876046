import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ee0eb12e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Vignette = _resolveComponent("Vignette")!
  const _component_CardLarge = _resolveComponent("CardLarge")!
  const _component_CardMedium = _resolveComponent("CardMedium")!
  const _component_InteractiveCard = _resolveComponent("InteractiveCard")!
  const _component_InlineDivider = _resolveComponent("InlineDivider")!
  const _component_TwoColumnLayout = _resolveComponent("TwoColumnLayout")!
  const _component_stil_grid = _resolveComponent("stil-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Vignette, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.puffPackage?.subject), 1)
      ]),
      _: 1
    }),
    (_ctx.cardItems)
      ? (_openBlock(), _createBlock(_component_stil_grid, {
          key: 0,
          variant: "flex",
          flow: "column",
          alignItems: "stretch",
          gap: 2
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layoutItems, (item, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, null, [
                (item.component === 'CardLarge' && item.puffIndex !== undefined)
                  ? (_openBlock(), _createBlock(_component_CardLarge, _mergeProps({
                      key: 0,
                      key: index
                    }, _ctx.cardItems[item.puffIndex], {
                      onOpen: _ctx.createOpenItem(_ctx.cardItems[item.puffIndex]),
                      onPlay: _ctx.createPlayItem(_ctx.cardItems[item.puffIndex]),
                      onPause: _ctx.pauseItem
                    }), null, 16, ["onOpen", "onPlay", "onPause"]))
                  : _createCommentVNode("", true),
                (item.component === 'CardMedium' && item.puffIndex !== undefined)
                  ? (_openBlock(), _createBlock(_component_CardMedium, _mergeProps({
                      key: 1,
                      key: index
                    }, _ctx.cardItems[item.puffIndex], {
                      onOpen: _ctx.createOpenItem(_ctx.cardItems[item.puffIndex]),
                      onPlay: _ctx.createPlayItem(_ctx.cardItems[item.puffIndex]),
                      onPause: _ctx.pauseItem,
                      fullWidth: true
                    }), null, 16, ["onOpen", "onPlay", "onPause"]))
                  : _createCommentVNode("", true),
                (
            item.component === 'Interactive' && item.puffIndex !== undefined
          )
                  ? (_openBlock(), _createBlock(_component_InteractiveCard, _mergeProps({
                      key: 2,
                      key: index
                    }, _ctx.cardItems[item.puffIndex], {
                      isLarge: _ctx.isLargeFeature(item.puffIndex),
                      ctaText: _ctx.cardItems[item.puffIndex].ctaText!,
                      onClick: _ctx.createOpenItem(_ctx.cardItems[item.puffIndex]),
                      isSummerPuff: _ctx.isSummerPuff(_ctx.cardItems[item.puffIndex]),
                      isProgramPuff: _ctx.isUSApoddenPuff(_ctx.cardItems[item.puffIndex])
                    }), null, 16, ["isLarge", "ctaText", "onClick", "isSummerPuff", "isProgramPuff"]))
                  : _createCommentVNode("", true),
                (item.component === 'Divider')
                  ? (_openBlock(), _createBlock(_component_InlineDivider, { key: index }))
                  : _createCommentVNode("", true),
                (
            item.component === 'CardMediumPair' && item.puffIndex !== undefined
          )
                  ? (_openBlock(), _createBlock(_component_TwoColumnLayout, { key: index }, {
                      default: _withCtx(() => [
                        _createVNode(_component_CardMedium, _mergeProps(_ctx.cardItems[item.puffIndex], {
                          onOpen: _ctx.createOpenItem(_ctx.cardItems[item.puffIndex]),
                          onPlay: _ctx.createPlayItem(_ctx.cardItems[item.puffIndex]),
                          onPause: _ctx.pauseItem
                        }), null, 16, ["onOpen", "onPlay", "onPause"]),
                        _createVNode(_component_CardMedium, _mergeProps(_ctx.cardItems[item.puffIndex + 1], {
                          onOpen: _ctx.createOpenItem(_ctx.cardItems[item.puffIndex + 1]),
                          onPlay: _ctx.createPlayItem(_ctx.cardItems[item.puffIndex + 1]),
                          onPause: _ctx.pauseItem
                        }), null, 16, ["onOpen", "onPlay", "onPause"])
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ], 64))
            }), 256))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}